import React from 'react';
import { graphql } from 'gatsby';
import Hod from '../src/components/Hod/Hod';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import SEO from '../src/helpers/seo';
import { Odin } from '../src/components/Odin/Odin';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import { Freya } from '../src/components/Freya/Freya';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';
import './nuestro_compromiso.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query nuestro_compromiso($locale: String!) {
    allCompromisoSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allCompromisoTitleHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          description
        }
      }
    }
    allCompromisoImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allCompromisoBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allCompromisoMosaicBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          image_alt
          description
          cta
          ctaText
        }
      }
    }
    allCompromisoTopPromoted(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttons {
            cta
            ctaText
            size
            color
          }
        }
      }
    }
    allCompromisoInformativeBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title
          characteristics
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allCompromisoRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class NuestroCompromiso extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allCompromisoTitleHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allCompromisoTitleHeaderBlock.edges[0].node.subtitle,
      subtitleType: 'div',
      image: {
        url: this.props.data.allCompromisoImageHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allCompromisoImageHeaderBlock.edges[0].node.alt,
      },
    };

    const h2Data = {
      title: tt('DESCUBRE NUESTRA RESPONSABILIDAD CORPORATIVA', this.props.pageContext.locale),
    };

    const odinData = {
      image: this.props.data.allCompromisoMosaicBlock.edges[0].node.image,
      alt: this.props.data.allCompromisoMosaicBlock.edges[0].node.image_alt,
      title: this.props.data.allCompromisoMosaicBlock.edges[0].node.description,
      buttons: {
        cta1: this.props.data.allCompromisoMosaicBlock.edges[0].node.cta,
        ctaText1: this.props.data.allCompromisoMosaicBlock.edges[0].node.ctaText,
        size: 'alone',
        color1: 'white',
        blank: true,
      },
    };

    const h3Data = {
      title: this.props.data.allCompromisoTitleHeaderBlock.edges[0].node.title.toUpperCase(),
    };

    const PrepareFreya = src => ({
      title: tt('BENEFICIOS DENTRO DEL PARQUE', this.props.pageContext.locale),
      isSlider: true,
      cards: src.edges
        .map(e => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: 'nuestro-compromiso',
          imgsize: 'promoted',
          title: e.node.title,
          buttons: {
            size: 'alone',
            color: 'white',
            ctaText: tt('MÁS INFO', this.props.pageContext.locale),
            popup: {
              enable: true,
              textpop: e.node.characteristics,
            },
          },
        }))
        .filter(e => e.image !== undefined),
    });

    const freyaData = {
      title: tt('RESPONSABILIDAD CORPORATIVA', this.props.pageContext.locale),
      cards: this.props.data.allCompromisoTopPromoted.edges.map(promotion => ({
        image: promotion.node.localImage.childImageSharp.fluid,
        altImage: 'nuestro-compromiso',
        title: promotion.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: promotion.node.buttons.cta,
          size: 'alone',
          color: 'white',
          ctaText: promotion.node.buttons.ctaText,
        },
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <SEO
            title={this.props.data.allCompromisoSeoBlock.edges[0].node._0.title}
            description={this.props.data.allCompromisoSeoBlock.edges[0].node._1.description}
            pathname={this.props.pageContext.url}
            lang={this.props.pageContext.locale}
          />
          <Hod
            data={this.props.data.allCompromisoRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allCompromisoImageHeaderBlock.edges[0].node.image}
          />
          <div className="general-index">
            <div className="compromiso-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allCompromisoBreadcrumb.edges[0].node.name}
              />
              <H2 data={h2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <H3 data={h3Data} />
              <div
                className="text-container"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allCompromisoTitleHeaderBlock.edges[0].node.description,
                }}
              />
              <Freya data={PrepareFreya(this.props.data.allCompromisoInformativeBlock)} />
              <H3 data={freyaData} />
              <Freya data={freyaData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NuestroCompromiso;
